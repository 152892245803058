import React from 'react';
import logo from '../assets/images/logo-modeli.png'
const AppFooter = (props: any) => {
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src={logo} alt="atlantis-layout" style={{
                    objectFit: 'contain',
                    width: 100,
                    height: 'auto'
                }} />
            </div>
            <span className="copyright">&#169; Modeli.ai</span>
        </div>
    );
};

export default AppFooter;
