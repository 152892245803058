
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfigService from '../../service/ConfigManagement/configService';
import NotifyController from '../../utilities/Toast';

export default function EditTutor() {
    const nav = useNavigate();
    const { id } = useParams();
    const [content, setContent] = useState('');
    const [urlS3, setURLS3] = useState({
        file: {},
        signedRequest: '',
        url: ''
    })
    const [formData, setFormData] = useState<any>({});

    async function onFinish(e: any) {
        e.preventDefault();
        formData.content = content;
        formData.cover_image = urlS3.url;
        urlS3.url && finalSaveS3()
        await ConfigService.getInstance().updateTutor(formData);
        NotifyController.success('Create tutor success');
        nav(`/tutor`)
    }

    const handleImageUpload = async (e: any) => {
        const fileName = e.target.files[0]
        const upload = await ConfigService.getInstance().uploadImage(fileName);
        const ob = {
            file: fileName,
            signedRequest: upload.data.signedRequest,
            url: upload.data.url
        }
        if (upload.success) {
            setURLS3(ob as any)
        }
    }

    const finalSaveS3 = () => {
        ConfigService.getInstance().uploadFileS3(urlS3.file, urlS3.signedRequest, urlS3.url)
    }

    function handleEditorChange(content: any, editor: any) {
        setContent(content)
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const getTutorDetail = async () => {
        const data = await ConfigService.getInstance().getTutorDetail(id);
        setFormData({
            id: data._id,
            title: data.title,
            group: data.group,
            summary: data.summary,
            lang: data.language,
            forUser: data.for_user,
        })
        setContent(data.content)
    }

    useEffect(() => {
        getTutorDetail();
    }, [])

    return (
        <form onSubmit={onFinish}>
            <div className="content3">
                <div className="profile-holder">
                    <div className="form-group files">
                        <label>Ảnh Bìa</label>
                        <input id="input-image" type="file" accept="image/*" onChange={handleImageUpload} />
                    </div>
                    <div>
                        <label>Ngôn ngữ (en/vi)</label>
                        <input
                            type="text"
                            name="lang"
                            placeholder="en/vi"
                            value={formData.lang}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Dành cho (beginner/experienced)</label>
                        <input
                            type="text"
                            name="forUser"
                            value={formData.forUser}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Tiêu đề</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="Nhập tiêu đề..."
                            value={formData.title}
                            onChange={handleChange}
                            required
                            maxLength={113}
                        />
                    </div>
                    <div>
                        <label>Loại tin</label>
                        <input
                            type="text"
                            name="group"
                            placeholder="Loại Tin Tức..."
                            value={formData.group}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Tóm tắt</label>
                        <textarea
                            name="summary"
                            placeholder="Nhập tóm tắt..."
                            value={formData.summary}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Nội dung</label>
                        {/* <Editor
                            apiKey='vay56zrvh9veuhcbway7pupzaoycpwvx06k3t4xzzsv3lfgv'
                            initialValue={content}
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleEditorChange}
                        /> */}
                        <div style={{ color: 'black' }}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                onChange={(event, editor) => {
                                    console.log(editor.getData());
                                    setContent(editor.getData())
                                }}
                                config={{
                                    mediaEmbed: {
                                        previewsInData: true
                                    }
                                }}
                            />
                        </div>

                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Button type="submit">Sửa Bài Viết</Button>
                    </div>
                </div>
            </div>
        </form>

    );
}