import { httpGet, httpPost } from "./HttpService";

export default class UserService {
    private static instance: UserService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new UserService();
        }
        return this.instance;
    }

    async getListUsers(limit: number, page: number) {
        const data = await httpGet(`dashboard/list-users?limit=${limit}&page=${page}`);
        return data.data
    }

    async getUserOrders(limit: number, page: number, userId: string) {
        const data = await httpPost(`dashboard/user-orders?limit=${limit}&page=${page}&userId=${userId}`, {});
        return data.data
    }
    async getPermissionByUser() {
        const data = await httpGet('/dashboard/permission/all')
        return data.data
    }
}
