import { IRole } from "../utilities/interface";
import { httpDelete, httpGet, httpPost, httpPut } from "./HttpService";

export default class RoleAdminService {
    private static instance: RoleAdminService;
    private constructor() {}
    public static getInstance() {
        if (!this.instance) {
            this.instance = new RoleAdminService();
        }
        return this.instance;
    }

    async getPermission(id: string) {
        const res = await httpGet('/dashboard/permission', {
            params: { id: id }
        })
        return res.data
    }

    async getAllPermission() {
        const res = await httpGet('/dashboard/permission/all')
        return res.data
    }


    async getRoles() {
        const res = await httpGet('/dashboard/role-admin');
        return res.data;
    }

    async editRoles(data: IRole) {
        const res = await httpPut('/dashboard/role-admin', data);
        return res.data;
    }

    async createRoles(id: string, name: string) {
        const res = await httpPost('/dashboard/role-admin', {
            name,
            id,
        });
        return res.data;
    }

    async deleteRoles(id: string) {
        const res = await httpDelete(`/dashboard/role-admin/${id}`);
        return res.data;
    }
}
