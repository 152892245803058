import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import ConfigService from '../../service/ConfigManagement/configService'
import { Dropdown } from 'primereact/dropdown'
import { WhitelistStatusEnum } from '../../utilities/enums'
import { Tag } from 'primereact/tag';

function ListWhitelist() {
    const [listData, setListData] = useState([])

    const getData = async () => {
        const dataApi = await ConfigService.getInstance().getAllWhitelist();
        setListData(dataApi)
    }

    const statusList = [
        { name: WhitelistStatusEnum.WAITING, code: WhitelistStatusEnum.WAITING },
        { name: WhitelistStatusEnum.APPROVED, code: WhitelistStatusEnum.APPROVED },
        { name: WhitelistStatusEnum.REJECTED, code: WhitelistStatusEnum.REJECTED },
    ];

    const onChangStatus = async (value:any,id:string) =>{
        const body = {
            whitelistId:id,
            status: value
        } 
        await ConfigService.getInstance().editWhitelist(body);
        getData()
    }

    const actionTemplate = (data:any) =>{
        const status = data.status 
        return (
            <Dropdown 
            value={status} 
            onChange={(e) => onChangStatus(e.value,data._id)} options={statusList} 
            optionLabel="name"
            optionValue="code"
             className="w-full md:w-14rem" />
        )
    }

    const templateStatus = (data:any) =>{
        const status = data.status;
        const color = (status === 'WAITING') ? 'info' : (status === 'APPROVED') ? 'success' : 'danger';
        return (
            <Tag className="p-2 w-8rem text-white" severity={color} value={status}></Tag>
        )
    }

    useEffect(()=>{
        getData()
    },[])
    return (
        <div>
            <DataTable width={'100%'} value={listData} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} selectOnEdit={true}>
                <Column field="email" header="email" filter filterPlaceholder="Search by user name"></Column>
                <Column field="status" header="Status" filter filterPlaceholder="Search by status" body={templateStatus}></Column>
                <Column  header="Edit Status" body={actionTemplate}></Column>
            </DataTable>
        </div>
    )
}

export default ListWhitelist