import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useEffect, useState } from 'react'
import ConfigService from '../../../service/ConfigManagement/configService'
import { InputText } from 'primereact/inputtext'
import NotifyController from '../../../utilities/Toast'
import VanillaJSONEditor from "../../../components/VanillaJSONEditor";

type ModalViewDetailConfigProps = {
  visible: boolean,
  isShowModalConfirm: boolean,
  setVisible: (i: boolean) => void,
  setIsShowModalConfirm: (i: boolean) => void,
  data: any,
  onEdit: (value: string) => void
}
function ModelViewDetailConfig({ visible, isShowModalConfirm, setIsShowModalConfirm, setVisible, data, onEdit }: ModalViewDetailConfigProps) {
  const [jsonData, setJsonData] = useState<any>(data);
  const [valueURLTemp, setValueURLTemp] = useState('')
  const [showModalURL, setShowModalURL] = useState(false)
  const [isCopy, setIsCopy] = useState('Copy')
  const [isUpload, setIsUpload] = useState(false)
  const [urlS3, setURLS3] = useState({
    file: {},
    signedRequest: '',
    url: ''
  })

  useEffect(() => {
    setJsonData({
      json: data
    });
  }, [data]);

  const onClickShowModal = () => {
    setIsShowModalConfirm(true)
  }
  const finalSaveS3 = () => {
    const save = ConfigService.getInstance().uploadFileS3(urlS3.file, urlS3.signedRequest, urlS3.url)
  }

  const handleImageUpload = async (e: any) => {
    const fileName = e.target.files[0]
    const upload = await ConfigService.getInstance().uploadImage(fileName);
    upload && setIsUpload(true)
    const ob = {
      file: fileName,
      signedRequest: upload.data.signedRequest,
      url: upload.data.url
    }
    if (upload.success) {
      setValueURLTemp(upload.data.url)
      setShowModalURL(true)
      setURLS3(ob as any)
    }
  }

  const onCopy = (link: string) => {
    navigator.clipboard.writeText(link);
    NotifyController.success('Copy success');
    setIsCopy('Copied');
    setShowModalURL(false)
  }

  const onSave = () => {
    if (jsonData.json) {
      onEdit(JSON.stringify(jsonData.json, undefined, 4)); isUpload && finalSaveS3()
    } else if (jsonData.text) {
      try {
        JSON.parse(jsonData.text)
        onEdit(jsonData.text); isUpload && finalSaveS3()
      } catch {
        NotifyController.error("Json data error")
      }
    } else {
      NotifyController.error("Json data null")
    }
  }

  const footerContent = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ textAlign: 'left' }}>
        <h5>Upload image</h5>
        <input id="input-image" type="file" accept="image/*" onChange={handleImageUpload} />
      </div>
      <div>
      <Button className='p-button-secondary' label="Discard" icon="pi pi-times" onClick={() => setVisible(false)} />
      <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={e => { onClickShowModal() }} autoFocus />
      </div>
    </div>
  );

  return (
    <div className='flex justify-content-center'>
      <Dialog onHide={() => setIsShowModalConfirm(false)} visible={isShowModalConfirm} header={'Do you want to save?'}>
        <div className='flex gap-3'>
          <Button className='p-button-secondary' label="Cancel" icon="pi pi-times" onClick={() => setIsShowModalConfirm(false)} />
          <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={onSave} autoFocus />
        </div>
      </Dialog>
      <Dialog header="Edit config" visible={visible} onHide={() => setVisible(false)}
        style={{ width: '95vw', height: '95vh' }}
        footer={footerContent}>
        <div style={{ display: 'flex' }}>
          <VanillaJSONEditor
            content={jsonData}
            readOnly={false}
            onChange={(setJsonData)}
          />
        </div>

        <Dialog header={'URL image '} visible={showModalURL} onHide={() => setShowModalURL(false)} style={{ width: '30%', height: '200px' }}  >
          <p>Copy url and past to field <b>image</b> of json file</p>
          <div className='flex justify-content-center align-item-center gap-2'>
            <InputText value={valueURLTemp} style={{
              width: '200px'
            }} disabled />
            <Button onClick={e => onCopy(valueURLTemp)} className='pi pi-copy'>{isCopy}</Button>
          </div>
        </Dialog>
      </Dialog>
    </div>
  )
}

export default ModelViewDetailConfig