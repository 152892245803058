import { useEffect, useState } from 'react';
import NotifyController from '../../utilities/Toast';
import ConfigService from '../../service/ConfigManagement/configService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';

const orderStatuses = [
    { label: 'Open', value: 'OPEN' },
    { label: 'In Progress', value: 'INPROGRESS' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Finished', value: 'FINISHED' }
];

export default function EditOrder() {
    const { id } = useParams();  // get the order ID from the URL parameters
    const [formData, setFormData] = useState({
        status: null
    });

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = async () => {
        try {
            const response = await ConfigService.getInstance().getOrderById(id);
            const order = response.data;
            setFormData({
                status: order.status
            });
        } catch (error) {
            NotifyController.error('Failed to fetch order details');
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onUpdate();
    };

    const onUpdate = () => {
        if (!formData.status) {
            NotifyController.error('Please select a status');
            return;
        }
        ConfigService.getInstance().updateOrder({ orderId: id, status: formData.status })
            .then(() => {
                NotifyController.success('Order status updated successfully');
                // navigate('/orders'); 
            })
            .catch((error: any) => {
                NotifyController.error('Update order status failed');
            });
    }

    const handleInputChange = (name: any, value: any) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ marginTop: '20px' }}>
                <Dropdown
                    value={formData.status}
                    options={orderStatuses}
                    onChange={(e) => handleInputChange('status', e.value)}
                    placeholder="Select Order Status"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <Button type="submit" label="Update Order Status" />
            </div>
        </form>
    );
}
