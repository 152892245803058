import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import SvelteJSONEditor from '../../components/VanillaJSONEditor';
import ConfigService from '../../service/ConfigManagement/configService';
import NotifyController from '../../utilities/Toast';

type ModalEditCustomizeProps = {
    isOpenModalEdit: boolean,
    setDataEditModal: Function,
    setIsOpenModalEdit: Function,
    dataEditModal: any,
    idEditModal: string

}
function ModalEditCustomize({ isOpenModalEdit, setDataEditModal, idEditModal, setIsOpenModalEdit, dataEditModal }: ModalEditCustomizeProps) {
    const [isUpload, setIsUpload] = useState(false)
    const [showModalURL, setShowModalURL] = useState(false)
    const [valueURLTemp, setValueURLTemp] = useState('')
    const [isCopy, setIsCopy] = useState('Copy')
    const [isCopyDisable, setIsCopyDisable] = useState(true)
    const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
    const [dataEdit, setDataEdit] = useState<any>({})
    const [urlS3, setURLS3] = useState({
        file: {},
        signedRequest: '',
        url: ''
    })
    const onClickBtnConfirm = async (data: any) => {
        let dataJson: any;
        if (data.text) {
            dataJson = JSON.parse(data.text)
        }
        if (data.json) {
            dataJson = data.json
        }
        const finalData = {
            "_id": idEditModal,
            data: dataJson
        }
        const isUpdate = await ConfigService.getInstance().putEditPromptCustomize(finalData);
        if (isUpdate.success) {
            NotifyController.success('edit success')
            setIsShowModalConfirm(false)
            setIsOpenModalEdit(false)
        }
    }
    const footerContent = (
        <div>
            <Button className='p-button-secondary' label="Discard" icon="pi pi-times" onClick={() => setIsOpenModalEdit(false)} />
            <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={e => onClickShowModal()} autoFocus />
        </div>
    );
    useEffect(() => {
        setDataEdit({
            json: dataEditModal
        });
    }, [dataEditModal]);
    const handleImageUpload = async (e: any) => {
        const fileName = e.target.files[0]
        const upload = await ConfigService.getInstance().uploadImage(fileName);
        upload && setIsUpload(true)
        const ob = {
            file: fileName,
            signedRequest: upload.data.signedRequest,
            url: upload.data.url
        }
        if (upload.success) {
            setValueURLTemp(upload.data.url)
            setShowModalURL(true)
            setURLS3(ob as any)
        }
    }
    const finalSaveS3 = (ob: any) => {
        const save = ConfigService.getInstance().uploadFileS3(ob.file, ob.signedRequest, ob.url)
    }
    const onCopy = (link: string) => {
        navigator.clipboard.writeText(link);
        NotifyController.success('Copy success');
        setIsCopy('Copied');
        setShowModalURL(false)
    }
    const onClickShowModal = () => {
        setIsShowModalConfirm(true)
    }
    return (
        <div>
            {isShowModalConfirm && <Dialog onHide={() => setIsShowModalConfirm(false)} visible={isShowModalConfirm} header={'Do you want to save?'}>
                <div className='flex gap-3'>
                    <Button className='p-button-secondary' label="Cancel" icon="pi pi-times" onClick={() => setIsShowModalConfirm(false)} />
                    <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={() => onClickBtnConfirm(dataEdit)} autoFocus />
                </div>
            </Dialog>}
            <Dialog visible={isOpenModalEdit} header="Edit" onHide={() => { setIsOpenModalEdit(false) }} footer={footerContent} className='w-full'>
                {/* <textarea rows={30} cols={70} onChange={(e) => setDataEditModal(JSON.parse(e.target.value))} >{JSON.stringify(dataEditModal, undefined, 4)}</textarea> */}
                <div className='flex'>
                    <SvelteJSONEditor
                        content={dataEdit}
                        onChange={(setDataEdit)}
                        readOnly={false}
                    />
                </div>
                <div>
                    Get url image
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                </div>
                <Dialog header={'URL image '} visible={showModalURL} onHide={() => setShowModalURL(false)} style={{ width: '30%', height: '200px' }}  >
                    <p>Click button Confirm to upload to cloud!</p>
                    <div className='flex justify-content-center align-item-center gap-2'>
                        <InputText value={valueURLTemp} style={{
                            width: '200px'
                        }} disabled />
                        <div className='flex flex-column gap-3'>
                            <Button onClick={e => { finalSaveS3(urlS3); setIsCopyDisable(false) }} disabled={!isCopyDisable}>Confirm Upload</Button>
                            <Button onClick={e => onCopy(valueURLTemp)} disabled={isCopyDisable} className='pi pi-copy'>{isCopy}</Button>
                        </div>
                    </div>
                </Dialog>
            </Dialog>
        </div>
    )
}

export default ModalEditCustomize