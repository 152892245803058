import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ConfigService from '../../service/ConfigManagement/configService';
import { InputText } from 'primereact/inputtext';
import NotifyController from '../../utilities/Toast';
import SvelteJSONEditor from '../../components/VanillaJSONEditor';
type ModalAddCustomizeProps = {
    isOpenModalAdd: boolean,
    setIsOpenModalAdd: Function,
    dataAdd: any
}
function ModalAddCustomize({ isOpenModalAdd, setIsOpenModalAdd, dataAdd }: ModalAddCustomizeProps) {
    const [isUpload, setIsUpload] = useState(false)
    const [dataEdit, setDataEdit] = useState<any>({})
    const [showModalURL, setShowModalURL] = useState(false)
    const [valueURLTemp, setValueURLTemp] = useState('')
    const [isCopy, setIsCopy] = useState('Copy')
    const [isCopyDisable, setIsCopyDisable] = useState(true)
    const [urlS3, setURLS3] = useState({
        file: {},
        signedRequest: '',
        url: ''
    })
    const inintalValue = {
        "name": "Hand",
        "code": "hand",
        "prompt": "Hand",
        "optionNumber": 1,
        "index": 0,
        "data": [
            {
                "name": "Short hair",
                "prompt": "Short hair",
                "imageUrl": "",
                "weight": 1.3,
                "code": "short.hair",
                "is_active": true
            }
        ]
    }
    const onClickBtnConfirm = async (data: any) => {
        let dataJson: any;
        if (data.text) {
            dataJson = JSON.parse(data.text)
        }
        if (data.json) {
            dataJson = data.json
        }
        dataAdd.data.push(dataJson);
        const isAdd = await ConfigService.getInstance().putEditPromptCustomize(dataAdd);
        if (isAdd.success) {
            NotifyController.success('Add success')
            setIsOpenModalAdd(false)
        }
    }
    const footerContent = (
        <div>
            <Button className='p-button-secondary' label="Discard" icon="pi pi-times" onClick={() => setIsOpenModalAdd(false)} />
            <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={e => onClickBtnConfirm(dataEdit)} autoFocus />
        </div>
    );
    const handleImageUpload = async (e: any) => {
        const fileName = e.target.files[0]
        const upload = await ConfigService.getInstance().uploadImage(fileName);
        upload && setIsUpload(true)
        const ob = {
            file: fileName,
            signedRequest: upload.data.signedRequest,
            url: upload.data.url
        }
        if (upload.success) {
            setValueURLTemp(upload.data.url)
            setShowModalURL(true)
            setURLS3(ob as any)
        }
    }
    const finalSaveS3 = (ob: any) => {
        const save = ConfigService.getInstance().uploadFileS3(ob.file, ob.signedRequest, ob.url)
    }
    const onCopy = (link: string) => {
        navigator.clipboard.writeText(link);
        NotifyController.success('Copy success');
        setIsCopy('Copied');
        setShowModalURL(false)
    }
    useEffect(() => {
        isOpenModalAdd && setDataEdit({
            json: inintalValue
        })
    }, [isOpenModalAdd])
    return (
        <div>
            <Dialog visible={isOpenModalAdd} header="Add" onHide={() => { setIsOpenModalAdd(false) }} footer={footerContent} className='w-full'>
                {/* <textarea rows={30} cols={70} onChange={(e) => setDataAddModal(JSON.parse(e.target.value))} >{JSON.stringify(dataAddModal, undefined, 4)}</textarea> */}
                <div className='flex '>
                    <SvelteJSONEditor
                        content={dataEdit}
                        onChange={(setDataEdit)}
                        readOnly={false}
                    />
                </div>
                <div>
                    Get url image
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                </div>
                <Dialog header={'URL image '} visible={showModalURL} onHide={() => setShowModalURL(false)} style={{ width: '30%', height: '200px' }}  >
                    <p>Click button Confirm to upload to cloud!</p>
                    <div className='flex justify-content-center align-item-center gap-2'>
                        <InputText value={valueURLTemp} style={{
                            width: '200px'
                        }} disabled />
                        <div className='flex flex-column gap-3'>
                            <Button onClick={e => { finalSaveS3(urlS3); setIsCopyDisable(false) }} disabled={!isCopyDisable}>Confirm Upload</Button>
                            <Button onClick={e => onCopy(valueURLTemp)} disabled={isCopyDisable} className='pi pi-copy'>{isCopy}</Button>
                        </div>
                    </div>
                </Dialog>
            </Dialog>
        </div>
    )
}

export default ModalAddCustomize