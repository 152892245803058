import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react'
import ConfigService from '../../service/ConfigManagement/configService'
import NotifyController from '../../utilities/Toast';

function ListAll() {
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [inputValue, setInputValue] = useState("");
    const [debouncedValue, setDebouncedValue] = useState("");
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [valueAfterEdit, setValueAfterEdit] = useState('');
    const [dataEdit, setDataEdit] = useState({
        key: '',
        value: ''
    });

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const getAPIListI18n = async (page: number, limit: number, debouncedValue: string) => {
        const data = await ConfigService.getInstance().getAllKeyI18n(page, limit, debouncedValue);
        setListData(data.items)
        setTotalRecord(data.totalRecord)
    }

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const detailsTemplate = (data: any) => {
        return (
            <div className='flex gap-2'>

                <Button
                    onClick={
                        () => {
                            setDataEdit({
                                key: data.key,
                                value: data.value
                            })
                            setIsOpenEdit(true)
                        }
                    }
                > Edit</Button >
            </div >
        )
    }
    const onSaveEdit = async (key: string, value: string) => {
        const edited = await ConfigService.getInstance().putEditKeyI18n(key, value);
        if (edited.success) {
            NotifyController.success('edit successfully')
        }
        setTimeout(() => {
            getAPIListI18n(1, 10, '')
            setIsOpenEdit(false)
        }, 500)
    }
    const footerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Button className='p-button-secondary' label="Discard" icon="pi pi-times" onClick={() => setIsOpenEdit(false)} />
                <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={e => { onSaveEdit(dataEdit.key, valueAfterEdit) }} autoFocus />
            </div>
        </div>
    );
    const onChangeValueEdit = (e: any) => {
        setValueAfterEdit(e.target.value)
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue, 500]);

    useEffect(() => {
        getAPIListI18n(page, limit, debouncedValue)
    }, [page, limit, debouncedValue])
    return (
        <div>
            <InputText onChange={handleInputChange} placeholder='Search by value'></InputText>
            <DataTable width={'100%'} value={listData} paginator={false} selectOnEdit={true}>
                <Column field="key" header="key" filter filterPlaceholder="Search by user key"></Column>
                <Column field="value" header="value" filter filterPlaceholder="Search by value" ></Column>
                <Column header="Action" body={detailsTemplate}></Column>
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
            {isOpenEdit && <Dialog className='w-max' onHide={() => setIsOpenEdit(false)} visible={isOpenEdit} footer={footerContent} >
                <div className='flex flex-column ga-4'>

                    Key: <div>{dataEdit.key}</div>
                    Value:<InputText defaultValue={dataEdit.value} onChange={onChangeValueEdit}></InputText>
                </div>
            </Dialog>}
        </div>
    )
}

export default ListAll