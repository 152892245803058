import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useSelector } from 'react-redux';
import { getUserState } from '../redux/reducers/userReducer';
import AuthService from '../service/AuthService';

const AppInlineMenu = (props: any) => {
    const menuRef = useRef(null);
    const user = useSelector(getUserState)
    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    const isStatic = () => {
        return props.menuMode === 'static';
    };

    const isSidebar = () => {
        return props.menuMode === 'sidebar';
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    return (
        <div className='layout-inline-menu'>
            {
                <ul ref={menuRef} className="layout-inline-menu-action-panel">
                    <li className="layout-inline-menu-action-item">
                        <button className="p-link" onClick={() => AuthService.getInstance().logout()}>
                            <i className="pi pi-power-off pi-fw"></i>
                            <span >Logout</span>
                        </button>
                    </li>
                </ul>}
        </div >
    );
};

export default AppInlineMenu;
