import React, { useEffect, useState } from 'react'
import ConfigService from '../../service/ConfigManagement/configService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function ListWebUI() {
    const [data, setData] = useState()
    const query = {
        "status": "active",
        "type": {
            "$in": [
                "fashion_app",
                "fashion_app_pod"
            ]
        }
    }
    const getApi = async () => {
        const listData = await ConfigService.getInstance().getAllWebUI(query)
        setData(listData.result);
    }
    const urlTemplate = (data:any)=>{
        const url = data.url
        return (
            <a href={url} target='_blank'>{url}</a>
        )
    }
    useEffect(() => {
        getApi()
    }, [])
    return (
        <div>
            <DataTable width={'100%'} value={data} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} selectOnEdit={true}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="_id" header="ID" filter filterPlaceholder="Search by id" sortable ></Column>
                <Column field="type" header="Type" sortable filter filterPlaceholder="Search by type" ></Column>
                <Column field="url" header="URL" filter filterPlaceholder="Search by url " sortable body={urlTemplate}></Column>
            </DataTable>
        </div>
    )
}

export default ListWebUI