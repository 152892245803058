import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import ConfigService from "../../service/ConfigManagement/configService";
import ModelViewDetailConfig from "../configManagement/AllConfig/ModalViewDetailConfig";

export default function ListProducts() {
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [listProducts, setlistProducts] = useState([])

    const [visibleModal, setVisibleModal] = useState(false)
    const [dataModal, setDataModal] = useState([]);
    const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)

    const onClickBtnEdit = (data: any) => {
        setVisibleModal(true);
        setDataModal(data)
    }

    const getListProducts = async () => {
        const data = await ConfigService.getInstance().getProducts();
        setlistProducts(data.data);
    }

    const actionTemplate = (data: any) => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button type="button" className="p-button-danger" icon="pi pi-pencil" onClick={e => onClickBtnEdit(data)}></Button>
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    const onEdit = async (value: string) => {
        const edited = await ConfigService.getInstance().updateProduct(JSON.parse(value));
        if (edited.success) {
            getListProducts()
            setVisibleModal(false)
            setIsShowModalConfirm(false)
        }
    }

    useEffect(() => {
        getListProducts();
    }, [])

    return (
        <div>
            <ModelViewDetailConfig visible={visibleModal} isShowModalConfirm={isShowModalConfirm} setIsShowModalConfirm={setIsShowModalConfirm} setVisible={setVisibleModal} data={dataModal} onEdit={onEdit} />
            <Dialog visible={visibleModalDelete} onHide={() => setVisibleModalDelete(false)} header={`Are you sure?`}>
                <div className='flex justify-content-center align-item-center gap-2'>
                    <Button className='p-button-secondary' label="Cancel" icon="pi pi-times" onClick={() => setVisibleModalDelete(false)} />
                </div>
            </Dialog>
            <DataTable width={'100%'} paginator={false} value={listProducts} selectOnEdit={true}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="product_code" header="Product Code" filter sortable ></Column>
                <Column field="title" header="Title" filter sortable ></Column>
                <Column field="amount_coin" header="Amount" filter sortable ></Column>
                {/* <Column field="price" header="Price" body={(record) => <div>{JSON.stringify(record)}</div>} filter sortable></Column> */}
                <Column field="is_sub" header="Is Sub" filter sortable ></Column>
                <Column field="feature_type" header="Feature" sortable filter ></Column>
                <Column field="max_concurrent" header="Max Concurrent" sortable filter ></Column>
                <Column field="topup_discount_percent" header="Top Up Discount Percent" sortable filter ></Column>
                <Column field="created_ts" header="Create At" body={bodyFormattedDateTime} sortable></Column>
                <Column body={actionTemplate} headerClassName="w-10rem" />
            </DataTable>
        </div>
    );
}