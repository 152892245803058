import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfigService from "../../service/ConfigManagement/configService";
import NotifyController from "../../utilities/Toast";

export default function ListTutor() {
    const nav = useNavigate();
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [deleteID, setDeleteID] = useState('')
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);

    const onClickDelete = (data: any) => {
        setDeleteID(data._id);
        setVisibleModalDelete(true)
    }

    const onDelete = async () => {
        await ConfigService.getInstance().deleteTutor(deleteID);
        NotifyController.success('Delete success!')
        getListTutor();
        setVisibleModalDelete(false)
    }

    const onClickBtnEdit = (data: any) => {
        nav('/tutor/edit/' + data._id)
    }

    const onClickBtnAddNew = (data: any) => {
        nav('/tutor/add')
    }

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const getListTutor = async () => {
        const data = await ConfigService.getInstance().getTutors(page, limit);
        setListData(data.data.result);
        setTotalRecord(data.data.totalRecord);
    }

    const imgTemplate = (data: any) => {
        const image = data.cover_image;
        return (
            <div className="flex align-items-center gap-2">
                <img src={image} width="100" />
            </div>
        );
    };

    const actionTemplate = (data: any) => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button type="button" className="p-button-danger" icon="pi pi-pencil" onClick={e => onClickBtnEdit(data)}></Button>
                <Button type="button" className='p-button-info' icon="pi pi-trash" onClick={e => onClickDelete(data)}></Button>
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    useEffect(() => {
        getListTutor();
    }, [page, limit])

    return (
        <div>
            <Dialog visible={visibleModalDelete} onHide={() => setVisibleModalDelete(false)} header={`Are you sure?`}>
                <div className='flex justify-content-center align-item-center gap-2'>
                    <Button className='p-button-secondary' label="Cancel" icon="pi pi-times" onClick={() => setVisibleModalDelete(false)} />
                    <Button className='p-button-danger' label="Delete" icon="pi pi-trash" onClick={() => onDelete()} autoFocus />
                </div>
            </Dialog>
            <Button onClick={onClickBtnAddNew}>Add New</Button>
            <DataTable width={'100%'} paginator={false} value={listData} selectOnEdit={true}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="title" header="Title" filter sortable ></Column>
                <Column field="cover_image" header="Cover Image" body={imgTemplate}></Column>
                <Column field="for_user" header="For User" filter sortable ></Column>
                <Column field="group" header="Group" sortable filter ></Column>
                <Column field="language" header="Language" sortable filter ></Column>
                <Column field="summary" header="Summary" sortable filter ></Column>
                <Column field="created_ts" header="Ngày tạo" body={bodyFormattedDateTime} sortable></Column>
                <Column body={actionTemplate} headerClassName="w-10rem" />
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div>
    );
}