import React, { useEffect, useState } from 'react'
import DashboardService from '../../service/DashboardService'
import ConfigService from '../../service/ConfigManagement/configService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import { useNavigate } from 'react-router-dom'
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext'
function ListAllAffiliate() {
    const [dataAffiliate, setDataAffiliate] = useState([])
    const [idViewDetail, setIdViewDetail] = useState('')
    const [dates, setDates] = useState<any>(undefined);
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [inputValue, setInputValue] = useState("");
    const [debouncedValue, setDebouncedValue] = useState("");
    const navigate = useNavigate()
    const getDataListAffiliate = async (page: number, limit: number, searchParams: string, startDate: number, endDate: number) => {
        const data = await ConfigService.getInstance().getAllAffiliates(page, limit, searchParams, startDate, endDate);
        setDataAffiliate(data.result)
        setTotalRecord(data.totalRecord)
    }

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const detailsTemplate = (data: any) => {
        return (
            <div className='flex gap-2'>

                <Button
                    onClick={
                        () => {
                            navigate(`/affiliate-detail/${data.affiliater_id}?email=${data.email}`)
                        }
                    }
                > View detail</Button >
            </div >
        )
    }
    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };


    const formatPriceVND = (data: any) => {
        const price = data.totalVndAmount
        return (
            <span>{price?.toLocaleString() + ' VND'}</span>
        );
    }
    const formatCommissionAmountVND = (data: any) => {
        const price = data.totalCommissionAmount
        return (
            <span>{price?.toLocaleString() + ' VND'}</span>
        );
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue, 500]);

    useEffect(() => {

        if (dates && dates.length > 0) {
            const startDate = dates[0].getTime();
            let endDate = 0
            if (dates.length > 1 && dates[1]) {
                endDate = dates[1].getTime();
            }
            if (startDate > 0 && endDate > 0) {
                // get by startDate and endDate
                getDataListAffiliate(page, limit, debouncedValue, startDate, endDate)
            }
        } else {
            const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();
            const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime();
            getDataListAffiliate(page, limit, debouncedValue, firstDayOfMonth, lastDayOfMonth)
        }
    }, [page, limit, debouncedValue, dates])

    return (
        <div>
            <div className='flex gap-4'>
                <div>
                    <InputText onChange={handleInputChange} placeholder='Search by value'></InputText>
                </div>
                <div>
                    <Calendar placeholder='Select start-end date' selectionMode="range" id="range" readOnlyInput value={dates} onChange={(e) => { setDates(e.value) }}></Calendar>
                </div>
            </div>
            <DataTable width={'100%'} value={dataAffiliate} paginator={false} selectOnEdit={true}>
                <Column field="username" header="username" filter filterPlaceholder="Search by username"></Column>
                <Column field="email" header="email" filter filterPlaceholder="Search by email" ></Column>
                <Column field="totalVndAmount" header="Tổng đã mua" sortable body={formatPriceVND}></Column>
                <Column field="totalCommissionAmount" header="Tổng hoa hồng" sortable body={formatCommissionAmountVND}></Column>

                <Column header="Action" body={detailsTemplate}></Column>
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div >
    )
}

export default ListAllAffiliate