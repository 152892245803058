import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import Joi from 'joi';
import { CUSTOM_FORM_DIALOG_FIELD_TYPE, MAXIMUM_WARNING_POINT } from '../utilities/constant';
import { Slider } from 'primereact/slider';
import { MultiSelect } from 'primereact/multiselect';
import { CKEditor } from 'ckeditor4-react';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';

interface confirmDialogProps {
    show: boolean;
    message?: string;
    fields: IDialogField[];
    defaultValue: any;
    onAccept: (data: any) => void;
    onDeny: () => void;
    className?: string;
}

interface IDialogField {
    name: string;
    label: string;
    type: CUSTOM_FORM_DIALOG_FIELD_TYPE;
    validate: any;
    selectValue?: Array<{ label: string; value: string }>;
    multiselectValue?: Array<{ name: string; code: string }>;
    maxValue?: number;
    otherProps?: any;
}

export const FormDialog: React.FC<confirmDialogProps> = ({ show, message, fields, defaultValue, onAccept, onDeny, className }) => {
    const [data, setData] = useState<any>(defaultValue);
    const [error, setError] = useState<Joi.ValidationErrorItem[]>([]);

    useEffect(() => {
        setData(defaultValue);
    }, [defaultValue]);
    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || '';
        let _data = { ...data };
        // @ts-ignore
        _data[`${name}`] = val;
        setData(_data);
    };

    const onSave = () => {
        const objSchema: any = {};
        for (const field of fields) {
            objSchema[field.name] = field.validate;
        }
        const validateSchema = Joi.object(objSchema).unknown();
        const validate = validateSchema.validate(data, {
            abortEarly: false
        });
        if (!validate.error) {
            setError([]);
            onAccept(data);
        } else {
            setError(validate.error.details);
            console.log(validate.error.details);
        }
    };

    const onSelectChange = (event: any, name: string) => {
        const val = event.value || '';
        let _data = { ...data };
        // @ts-ignore
        _data[`${name}`] = val;
        setData(_data);
    };

    const onSliderChange = (event: any, name: string) => {
        const val = event.value || '';
        let _data = { ...data };
        // @ts-ignore
        _data[`${name}`] = val;
        setData(_data);
    };

    const onChangeCKEditor = (evt: any, name: string) => {
        const dataEditor = evt.editor.getData()
        setData((prev: any) => ({
            ...prev,
            [name]: dataEditor
        }))
    }

    const onChangeSwitch = (e: any, name: string) => {
        setData((prev: any) => ({
            ...prev,
            [name]: e.value
        }))
    }

    const onChangeDate = (e: any, name: string) => {
        const val = e.value
        let _data = { ...data };
        // @ts-ignore
        _data[`${name}`] = val;
        setData(_data);
    }

    const generateField = (field: IDialogField) => {
        let result = <></>;
        const errorMessage = error.find((e) => e.context?.key === field.name)?.message;
        switch (field.type) {
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.text:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <InputText className={errorMessage ? 'p-invalid' : ''} id={field.name} value={data[field.name]} onChange={(e) => onInputChange(e, field.name)} {...field.otherProps} />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.number:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <InputText className={errorMessage ? 'p-invalid' : ''} type={'number'} id={field.name} value={data[field.name]} onChange={(e) => onInputChange(e, field.name)} />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.areaText:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <InputTextarea className={errorMessage ? 'p-invalid' : ''} id={field.name} value={data[field.name]} onChange={(e) => onInputChange(e, field.name)} rows={3} cols={20} />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;

            case CUSTOM_FORM_DIALOG_FIELD_TYPE.select:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <Dropdown optionLabel={field.otherProps?.optionLabel || 'value'} options={field.selectValue} value={data[field.name]} onChange={(e) => onSelectChange(e, field.name)}></Dropdown>
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.multiSelect:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>{field.label}</label>
                        <MultiSelect
                            value={data[field.name]}
                            onChange={(e) => onSelectChange(e, field.name)}
                            options={field.multiselectValue}
                            optionLabel="value"
                            filter
                            // itemTemplate={itemTemplate}
                            // selectedItemTemplate={selectedItemTemplate}
                            // className="multiselect-custom"
                        />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.slider:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>
                            {field.label}: {data[field.name]}
                        </label>
                        <Slider value={data[field.name]} onChange={(e: any) => onSliderChange(e, field.name)} max={field.maxValue ?? MAXIMUM_WARNING_POINT} />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.switchConfirm:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>
                            {field.label}
                        </label>
                        <div className='flex align-items-center justify-content-center w-100'>
                            <div className='mr-3'>Abort</div>
                            <InputSwitch checked={data[field.name]} onChange={(e) => onChangeSwitch(e, field.name)} />
                            <div className='ml-3'>Confirm</div>
                        </div>
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.richText:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>
                            {field.label}
                        </label>
                        <CKEditor
                            initData={data[field.name]}
                            name={field.name}
                            config={{
                                language: 'en',
                                // skin: 'moono'
                            }}
                            onChange={(event: any) => onChangeCKEditor(event, field.name)}
                        />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
                break;
            case CUSTOM_FORM_DIALOG_FIELD_TYPE.date:
                result = (
                    <div className="field" key={field.name}>
                        <label htmlFor={field.name}>
                            {field.label}
                        </label>
                        <Calendar value={new Date(data[field.name])} onChange={(e) => onChangeDate(e, field.name)} showTime hourFormat="12" {...field.otherProps} />
                        <small id="username-help" className="p-error">
                            {errorMessage}
                        </small>
                    </div>
                );
        }
        return result;
    };

    const DialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onDeny} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={onSave} />
        </>
    );
    return (
        <>
            <Dialog visible={show} style={{ width: '450px' }} header={message ?? 'Form'} modal contentStyle={{ position: 'relative' }} className={classNames("p-fluid", className)} footer={DialogFooter} onHide={onDeny}>
                {fields.map(generateField)}
            </Dialog>
        </>
    );
};

const itemTemplate = (option: any) => {
    return (
        <div className="flex align-items-center">
            <span>{option.name}</span>
        </div>
    );
};

const selectedItemTemplate = (option: any) => {
    if (option) {
        return (
            <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                <span>{option.name}</span>
            </div>
        );
    }

    return 'select';
};
