
import { useEffect, useState } from 'react';
import NotifyController from '../../utilities/Toast';
import ConfigService from '../../service/ConfigManagement/configService';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


const cycleTypes = [
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Yearly', value: 'yearly' }
];

const orderStatuses = [
    { label: 'Open', value: 'OPEN' },
    { label: 'In Progress', value: 'INPROGRESS' },
    { label: 'Failed', value: 'FAILED' },
    { label: 'Finished', value: 'FINISHED' }
];

export default function AddOrder() {
    const navigate = useNavigate();
    const [products, setProducts] = useState<any>([]);
    const [formData, setFormData] = useState({
        cycleType: null,
        userId: '',
        status: null,
        productId: '',
        coinAmount: null,
        amount: null
    });

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onFinish();
    };

    const onFinish = () => {
        if (!formData.cycleType || !formData.userId || !formData.status || !formData.productId || !formData.coinAmount || !formData.amount) {
            NotifyController.error('Please fill all fields');
            return;
        }
        ConfigService.getInstance().addOrder(formData)
            .then(() => {
                NotifyController.success('Create order success');
                // navigate('/order')
            })
            .catch((error) => {
                NotifyController.error('Create order failed');
            });
    }

    const handleInputChange = (name: any, value: any) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const getListProducts = async () => {
        const data = await ConfigService.getInstance().getProducts();
        setProducts(data.data.map((item: any) => {
            return {
                label: item.product_code,
                value: item._id
            }
        }));
    }
    useEffect(() => { getListProducts() }, [])
    return (
        <form onSubmit={handleSubmit}>
            <div style={{ marginTop: '20px' }}>
                <Dropdown
                    value={formData.cycleType}
                    options={cycleTypes}
                    onChange={(e) => handleInputChange('cycleType', e.value)}
                    placeholder="Select Cycle Type"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <InputText
                    value={formData.userId}
                    onChange={(e) => handleInputChange('userId', e.target.value)}
                    placeholder="User ID"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <Dropdown
                    value={formData.status}
                    options={orderStatuses}
                    onChange={(e) => handleInputChange('status', e.value)}
                    placeholder="Select Order Status"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <Dropdown
                    value={formData.productId}
                    options={products}
                    onChange={(e) => handleInputChange('productId', e.value)}
                    placeholder="Select product"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <InputNumber
                    value={formData.coinAmount}
                    onValueChange={(e) => handleInputChange('coinAmount', e.value)}
                    placeholder="Coin Amount"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <InputNumber
                    value={formData.amount}
                    onValueChange={(e) => handleInputChange('amount', e.value)}
                    placeholder="Amount"
                />
            </div>
            <div style={{ marginTop: '20px' }}>
                <Button type="submit" label="Tạo Order" />
            </div>
        </form>
    );
}