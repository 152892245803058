import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { FormDialog } from '../../components/FormDialog';
import { CUSTOM_FORM_DIALOG_FIELD_TYPE } from '../../utilities/constant';
import Joi from 'joi';
import NotifyController from '../../utilities/Toast';
import { Checkbox } from 'primereact/checkbox';
import { Divider, Input, Select, Space, Button as ButtonAntd } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { IPermissionRole, IRole } from '../../utilities/interface';
import RoleAdminService from '../../service/RoleAdminService';

interface IOptionPermission {
    id: string;
    options: string[];
}

const defaultCurrentData: IRole = {
    id: '',
    name: ''
};

const AdminPermissionManagement = () => {
    const [roles, setRoles] = useState<IRole[]>([]);
    const [currentData, setCurrentData] = useState<IRole>(defaultCurrentData);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [formDialogShow, setFormDialogShow] = useState<boolean>(false);

    const [optionsPermission, setOptionsPermission] = useState<IOptionPermission[]>([]);

    const [valueOption, setValueOption] = useState<string>('');
    const inputRef = useRef<InputRef>(null);

    const onChangeValueOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueOption(event.target.value);
    };

    const addItem = (idPermission: string) => {
        const indexOptionPermission = optionsPermission.findIndex((item) => item.id === idPermission);

        const tempOptionsPermission = JSON.parse(JSON.stringify(optionsPermission));
        const indexOption = tempOptionsPermission[indexOptionPermission].options.findIndex((option: string) => option === valueOption);
        if (indexOption === -1) {
            tempOptionsPermission[indexOptionPermission].options.push(valueOption);
        } else {
            NotifyController.warning('Option is existed');
        }
        setOptionsPermission(tempOptionsPermission);
        setValueOption('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const fields = useMemo(() => {
        const tempField = [
            {
                name: 'id',
                label: 'Role Id',
                type: CUSTOM_FORM_DIALOG_FIELD_TYPE.text,
                validate: Joi.string().min(3).pattern(/^\S+$/, {name: '"No contain space"'}).required()
            },
            {
                name: 'name',
                label: 'Role Name',
                type: CUSTOM_FORM_DIALOG_FIELD_TYPE.text,
                validate: Joi.string().min(3).required()
            }
        ];
        if (isEdit) {
            return tempField.slice(1);
        }
        return tempField;
    }, [isEdit]);

    const getAllRole = useCallback(() => {
        RoleAdminService.getInstance()
            .getRoles()
            .then((res) => {
                const dataRole = res.data?.result?.map((item: IRole) => ({
                    id: item.id,
                    name: item.name,
                    permissions: item.permissions
                }));
                setRoles(dataRole);
                const tempOptionPermission: IOptionPermission[] = [];
                dataRole.forEach((item: IRole) => {
                    item.permissions?.forEach((itemPermission: IPermissionRole) => {
                        if (itemPermission.type === 'number') {
                            const indexPermissonExist = tempOptionPermission.findIndex((itemOption) => itemOption.id === itemPermission.id);
                            if (indexPermissonExist === -1) {
                                tempOptionPermission.push({
                                    id: itemPermission.id,
                                    options: [itemPermission.value?.toString()]
                                });
                            } else {
                                const indexOptions = tempOptionPermission[indexPermissonExist].options.findIndex((itemOption) => itemOption === itemPermission.value?.toString());
                                if (indexOptions === -1) {
                                    tempOptionPermission[indexPermissonExist].options.push(itemPermission.value?.toString());
                                }
                            }
                        }
                    });
                });
                setOptionsPermission(tempOptionPermission);
            });
    }, []);

    useEffect(() => {
        getAllRole();
    }, [getAllRole]);

    const onChangeValuePermission = (idRole: number, idPermisison: string, e: any) => {
        const tempRoles = JSON.parse(JSON.stringify(roles));
        const indexPermission = tempRoles[idRole].permissions.findIndex((item: IPermissionRole) => item.id === idPermisison);
        tempRoles[idRole].permissions[indexPermission].value = e.target.checked;
        RoleAdminService.getInstance()
            .editRoles(tempRoles[idRole])
            .then((res) => {
                console.log('res edit', res);
                // NotifyController.success()
            })
            .catch((e) => {
                NotifyController.error(e?.message);
            });
        setRoles(tempRoles);
    };

    const onChangeValuePermissionNumber = (idRole: number, idPermisison: string, value: any) => {
        const tempRoles = JSON.parse(JSON.stringify(roles));
        const indexPermission = tempRoles[idRole].permissions.findIndex((item: IPermissionRole) => item.id === idPermisison);
        tempRoles[idRole].permissions[indexPermission].value = value;
        RoleAdminService.getInstance()
            .editRoles(tempRoles[idRole])
            .then((res) => {
                console.log('res edit', res);
                // NotifyController.success()
            })
            .catch((e) => {
                NotifyController.error(e?.message);
            });
        console.log('onChangeValuePermission ~ tempRoles:', tempRoles);
        setRoles(tempRoles);
    };

    const onSubmit = async (data: IRole) => {
        if (isEdit) {
            await RoleAdminService.getInstance()
                .editRoles({ id: data.id, name: data.name, permissions: data.permissions })
                .then((res) => NotifyController.success('Edit role succesfully'))
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                });
            setFormDialogShow(false);
            setIsEdit(false);
        } else {
            await RoleAdminService.getInstance()
                .createRoles(data.id, data.name)
                .then((res) => NotifyController.success('Create role successfully'))
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                })
                .finally(() => {
                    setCurrentData(defaultCurrentData);
                    setFormDialogShow(false);
                });
        }
        getAllRole();
    };
    const onDelete = async () => {
        await RoleAdminService.getInstance()
            .deleteRoles(currentData?.id)
            .then((res) => NotifyController.success('Delete role succesfully'))
            .catch((error) => {
                NotifyController.error(error?.message);
                console.log(error);
            })
            .finally(() => {
                getAllRole();
            });
        setDeleteDialog(false);
    };

    const isHideEdit = (role: IRole) => role.id === 'ADMIN';

    const renderTablePermission = () => {
        if (roles.length > 0) {
            const permissions = roles.sort((r1, r2) => (r2.permissions?.length ?? 0) - (r1.permissions?.length ?? 0))[0].permissions; // assuming all roles have the same set of permissions
            return (
                <table className="table-permission">
                    <thead>
                        <tr>
                            <th>Name</th>
                            {roles.map((role) => (
                                <th key={role.id}>
                                    <div className="d-flex align-items-center">
                                        {role.name}
                                        {!isHideEdit(role) && (
                                            <>
                                                <Button
                                                    icon="pi pi-pencil"
                                                    className="mx-3"
                                                    style={{ background: 'unset' }}
                                                    onClick={() => {
                                                        setIsEdit(true);
                                                        setCurrentData({
                                                            id: role.id,
                                                            name: role.name,
                                                            permissions: role.permissions
                                                        });
                                                        setFormDialogShow(true);
                                                    }}
                                                />
                                                <Button
                                                    icon="pi pi-trash"
                                                    style={{ background: 'unset' }}
                                                    onClick={() => {
                                                        setCurrentData({
                                                            id: role.id,
                                                            name: role.name
                                                        });
                                                        setDeleteDialog(true);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {permissions?.map((permission, indexPermission) => (
                            <tr key={permission.id}>
                                <td>{permission.id}</td>
                                {roles.map((role, indexRole) => {
                                    const valuePermission = role.permissions?.find((p) => p.id === permission.id)?.value;
                                    if (permission.type === 'boolean') {
                                        return (
                                            <td className="td-permission" key={`${role.id}-${permission.id}`}>
                                                <Checkbox name={permission.id} checked={valuePermission} onChange={(e) => onChangeValuePermission(indexRole, permission.id, e)} />
                                            </td>
                                        );
                                    } else if (permission.type === 'number') {
                                        return (
                                            <td key={`${role.id}-${permission.id}`}>
                                                {/* {valuePermission === Number.MAX_SAFE_INTEGER ? 'Unlimited' : valuePermission?.toString()} */}
                                                <Select
                                                    style={{ width: 140, background: 'transparent' }}
                                                    value={{
                                                        label: Number(valuePermission) === Number.MAX_SAFE_INTEGER ? 'Unlimited' : valuePermission,
                                                        value: Number(valuePermission) === Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : Number(valuePermission)
                                                    }}
                                                    onChange={(e) => onChangeValuePermissionNumber(indexRole, permission.id, e)}
                                                    dropdownRender={(menu) => (
                                                        <>
                                                            {menu}
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <Space style={{ padding: '0 8px 4px' }}>
                                                                <Input placeholder="5" ref={inputRef} value={valueOption} onChange={onChangeValueOption} />
                                                                <ButtonAntd type="text" icon={<PlusOutlined />} onClick={() => addItem(permission.id)} />
                                                            </Space>
                                                        </>
                                                    )}
                                                    options={optionsPermission
                                                        .find((itemOption) => itemOption.id === permission.id)
                                                        ?.options?.map((itemOp) => ({
                                                            label: Number(itemOp) === Number.MAX_SAFE_INTEGER ? 'Unlimited' : itemOp,
                                                            value: Number(itemOp) === Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : +itemOp
                                                        }))}
                                                />
                                            </td>
                                        );
                                    }
                                    return null;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
        return null;
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card" style={{ overflowX: 'auto' }}>
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h5>Permission</h5>
                        <Button
                            onClick={() => {
                                setCurrentData(defaultCurrentData);
                                setFormDialogShow(true);
                            }}
                            icon="pi pi-plus mr-2"
                            className="p-button-success"
                        >
                            Add new role
                        </Button>
                    </div>

                    {renderTablePermission()}
                    <ConfirmDialog
                        show={deleteDialog}
                        message={'Please confirm the deletion of this role?'}
                        onAccept={function (): void {
                            onDelete();
                        }}
                        onDeny={function (): void {
                            setDeleteDialog(false);
                        }}
                    />
                    <FormDialog
                        show={formDialogShow}
                        fields={fields}
                        defaultValue={currentData}
                        onAccept={function (data: any): void {
                            onSubmit(data);
                        }}
                        onDeny={function (): void {
                            setFormDialogShow(false);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminPermissionManagement;
