import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import ConfigService from '../../../service/ConfigManagement/configService';
import VanillaJSONEditor from "../../../components/VanillaJSONEditor";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import NotifyController from '../../../utilities/Toast';

function ListConfigs() {
    const [dataListConfig, setDataListConfig] = useState([])
    const [dataEdit, setDataEdit] = useState<any>({})
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
    const [keyDelete, setKeyDelete] = useState('')
    const getList = async () => {
        const data = await ConfigService.getInstance().getAllNewConfigs();
        setDataListConfig(data.data)
    }
    const valueTemplate = (data: any) => {
        const config_value = data.config_value;
        return (
            <div className="flex align-items-center h-20rem w-15rem gap-2 text-ellipsis whitespace-nowrap overflow-hidden">
                {config_value}
            </div>
        );
    }
    const detailsTemplate = (data: any) => {
        return (
            <div className='flex gap-2'>

                <Button
                    onClick={
                        () => {
                            setDataEdit({
                                json: data
                            })
                            setIsOpenModal(true)
                        }
                    }
                > Details</Button >
                <Button
                    className='p-button-danger' label="Delete" icon="pi pi-trash"
                    onClick={
                        () => {
                            onSetModalConfirm(data.config_key, true)
                        }
                    }
                > </Button >
            </div>
        )
    }
    const onSetModalConfirm = (key: string, isOpen: boolean) => {
        setKeyDelete(key)
        setIsOpenModalConfirm(isOpen)
    }
    const onEdit = async () => {
        let dataJson: any;
        if (dataEdit.text) {
            dataJson = JSON.parse(dataEdit.text)
        }
        if (dataEdit.json) {
            dataJson = dataEdit.json
        }
        const configKey = dataJson.config_key;
        const configValue = dataJson.config_value;
        const edited = await ConfigService.getInstance().editConfig(configKey, configValue);
        if (edited.success) {
            NotifyController.success('Edit successfully!')
            getList()
        } else {
            NotifyController.error('Something went wrong!')
        }

    }
    const onDelete = async (key: string) => {
        const deleted = await ConfigService.getInstance().deleteNewConfig(key);
        if (deleted.success) {
            NotifyController.success('Delete successfully!')
            setIsOpenModalConfirm(false)
            getList()
        } else {
            NotifyController.error('Something went wrong!')
        }
    }
    const footerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <Button className='p-button-secondary' label="Discard" icon="pi pi-times" onClick={() => setIsOpenModal(false)} />
                <Button className='p-button-success' label="Save" icon="pi pi-check" onClick={e => { onEdit() }} autoFocus />
            </div>
        </div>
    );

    useEffect(() => {
        getList();
    }, [])
    return (
        <div>
            <DataTable width={'100%'} value={dataListConfig} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} selectOnEdit={true}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="config_key" header="config_key" filter filterPlaceholder="Search by config value" style={{
                    maxHeight: '100px',
                    width: '50%'
                }} sortable ></Column>
                <Column field="config_value" header="config_value" filter filterPlaceholder="Search by config value" style={{
                    maxHeight: '100px',
                    width: '200px'
                }} body={valueTemplate} sortable ></Column>
                <Column header="Action" body={detailsTemplate}></Column>
            </DataTable>
            {isOpenModalConfirm && <Dialog visible={isOpenModalConfirm} onHide={() => { setIsOpenModalConfirm(false) }}
                header={
                    <div>
                        Do you want delete  {keyDelete} ?
                    </div>
                }
                className='flex justify-content-center'
            >
                <div className='flex align-items-center justify-content-center gap-4'>
                    <Button
                        label="Discard"
                        onClick={() => {
                            setIsOpenModalConfirm(false)
                        }}
                    ></Button>
                    <Button
                        className='p-button-danger' label="Delete" icon="pi pi-trash"
                        onClick={() => {
                            onDelete(keyDelete)
                        }}
                    ></Button>

                </div>
            </Dialog>}
            {isOpenModal && <Dialog visible={isOpenModal} onHide={() => { setIsOpenModal(false) }} footer={footerContent}>
                <div style={{ display: 'flex' }}>
                    <VanillaJSONEditor
                        content={dataEdit}
                        onChange={(setDataEdit)}
                        readOnly={false}
                    />
                </div>
            </Dialog>}
        </div>
    )
}

export default ListConfigs