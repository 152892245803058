import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import ConfigService from "../../service/ConfigManagement/configService";
import { Paginator } from "primereact/paginator";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";

export default function ListOrder() {
    const nav = useNavigate();
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);

    const onClickBtnEdit = (data: any) => {
        nav('/order/edit/' + data._id)
    }

    const onClickBtnAddNew = (data: any) => {
        nav('/order/add')
    }

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const getListOrder = async () => {
        const data = await ConfigService.getInstance().getOrders(page, limit);
        setListData(data.data.orders);
        setTotalRecord(data.data.totalOrders);
    }

    const actionTemplate = (data: any) => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button type="button" className="p-button-danger" icon="pi pi-pencil" onClick={e => onClickBtnEdit(data)}></Button>
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.createAt;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    useEffect(() => {
        getListOrder();
    }, [page, limit])

    return (
        <div>
            <Dialog visible={visibleModalDelete} onHide={() => setVisibleModalDelete(false)} header={`Are you sure?`}>
                <div className='flex justify-content-center align-item-center gap-2'>
                    <Button className='p-button-secondary' label="Cancel" icon="pi pi-times" onClick={() => setVisibleModalDelete(false)} />
                </div>
            </Dialog>
            <Button onClick={onClickBtnAddNew}>Add New</Button>
            <DataTable width={'100%'} paginator={false} value={listData} selectOnEdit={true}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="email" header="Email" filter sortable ></Column>
                <Column field="username" header="Username" filter sortable ></Column>
                <Column field="amount" header="Amount" filter sortable ></Column>
                <Column field="coins" header="Coins" filter sortable></Column>
                <Column field="currency" header="Currency" filter sortable ></Column>
                <Column field="gateway" header="Gateway" sortable filter ></Column>
                <Column field="name" header="Name" sortable filter ></Column>
                <Column field="status" header="Status" sortable filter ></Column>
                <Column field="feature_type" header="Type" sortable filter ></Column>
                <Column field="createAt" header="Create At" body={bodyFormattedDateTime} sortable></Column>
                <Column body={actionTemplate} headerClassName="w-10rem" />
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div>
    );
}