import Axios from "axios";
import { deleteToken, persistToken } from "./LocalStorageService";

export default class AuthService {
    private static instance: AuthService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
        }
        return this.instance;
    }
    async login(email: string, password: string) {
        const res = await Axios.post(process.env.REACT_APP_BE + '/dashboard/login', { email, password }).then((res) => res.data)
        if (res?.data?.token)
            persistToken(res?.data?.token)
        return res
    }

    async logout() {
        deleteToken()
        window.location.reload()
    }
}
