import { useState } from 'react';
import { Column } from 'primereact/column';
import { CustomDataTable, filterApplyTemplate, filterClearTemplate, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import UserService from '../service/UserService';
import { InputNumber } from 'primereact/inputnumber';

const ListUsers = () => {
    const [refresh, setRefresh] = useState<boolean>(false)
    const [totalDirect, setTotalDirect] = useState<number>(0)
    const [totalSocial, setSocial] = useState<number>(0)

    const onOptionChange = async (option: tableOptions) => {
        const users = await UserService.getInstance().getListUsers(option.rowPerPage, option.page).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        setSocial(users.data.socialMediaCount)
        setTotalDirect(users.data.directCount)
        return { total: users.data.totalRecords, data: users.data.users }
    }

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts || 0;
        const date = new Date(timestamp) || new Date(); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0] || null;

        return (
            <span>{formattedDateTime}</span>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>List Users</h5>
                    <div className='flex gap-4 mb-2'>
                        Direct :  <InputNumber value={totalDirect} disabled></InputNumber>
                        Social :  <InputNumber value={totalSocial} disabled></InputNumber>
                    </div>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        refresh={refresh}
                        onClickEnabled={true}
                    >
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Ngày tạo" headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="_id" header="ID" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="username" header="Username" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="email" header="Email" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="brand_name" header="Brand Name" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="role_in_organization" header="Roles" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="years_in_operation" header="Years in operation" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="phone_number" header="Phone" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="shop_size" header="Shop Size" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="shop_category" header="Shop Category" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="shop_info" header="Shop Info" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="status" header="Status" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="numberOfGen" header="Gen Number" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="source" header="Source" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="location_info" header="Country" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                    </CustomDataTable>
                </div>
            </div>
        </div>
    );
};

export default ListUsers;
