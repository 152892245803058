import { InputText } from 'primereact/inputtext';
import  { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import ConfigService from '../service/ConfigManagement/configService';
import NotifyController from '../utilities/Toast';
import UserService from '../service/UserService';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';

function DetailUser() {
    const { id } = useParams();
    const { state } = useLocation();
    const {
        username,
        email,
        brand_name,
        phone_number,
        shop_category,
        shop_info,
        shop_size,
        years_in_operation,
        role_in_organization,
        created_ts,
        status,
        source,
        roles,
        location_info,
        first_commission_for_affiliater,
        next_commission_for_affiliater,
        discount_for_user,
        time_coins,
        perm_coins,
        free_coins,
    } = state as any
    const initialValueUserInfo = {
        username,
        email,
        brand_name,
        phone_number,
        shop_category,
        shop_info,
        shop_size,
        years_in_operation,
        role_in_organization,
        status,
        source,
        roles,
        location_info,
        first_commission_for_affiliater,
        next_commission_for_affiliater,
        discount_for_user,
        time_coins,
        perm_coins,
        free_coins,
    }
    const [userInfo, setUserInfo] = useState(initialValueUserInfo)
    const [selectedRoles, setSelectedRoles] = useState([...userInfo.roles])
    const [rolesInput, setRolesInput] = useState('')
    const [listData, setListData] = useState([])
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);

    const navigate = useNavigate()

    const onChangeValueInput = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserInfo({ ...userInfo, [name]: value })
    }

    const convertTimestampToDateTime = (timestamp: number) => {
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];
        return formattedDateTime
    }

    const SaveEditRoles = async () => {
        if (id) {
            const body = {
                userId: id,
                editData: {
                    roles: selectedRoles,
                    firstCommission: userInfo.first_commission_for_affiliater,
                    nextCommission: userInfo.next_commission_for_affiliater,
                    discount: userInfo.discount_for_user,
                }
            }
            const editStatus = await ConfigService.getInstance().editRolesByIdUser(body);
            if (editStatus.success) {
                NotifyController.success("Edit Success")
            } else {
                NotifyController.success(editStatus.message)
            }
        }
    }

    const getListOrder = async () => {
        const data = await UserService.getInstance().getUserOrders(limit, page, id as any).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        setListData(data.data.orders);
        setTotalRecord(data.data.totalOrders);
    }

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.createAt;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    useEffect(() => {
        getListOrder();
    }, [page, limit])

    useEffect(() => {
        if (!id) {
            navigate('/users-management/list-users')
        }
    }, [id])
    return (
        <div className='flex flex-column gap-2'>
            <h1>User Detail</h1>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Name:
                    </span>
                    <InputText name={'username'} placeholder="Name" value={userInfo.username} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Email:
                    </span>
                    <InputText name='email' placeholder="email" value={userInfo.email} onChange={onChangeValueInput} />
                </div>
            </div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Brand name:
                    </span>
                    <InputText name='brand_name' placeholder="brand_name" value={userInfo.brand_name} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Phone number:
                    </span>
                    <InputText name='phone_number' placeholder="phone_number" value={userInfo.phone_number} onChange={onChangeValueInput} />
                </div></div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Shop category:
                    </span>
                    <InputText name='shop_category' placeholder="shop_category" value={userInfo.shop_category} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Shop info:
                    </span>
                    <InputText name='shop_info' placeholder="shop_info" value={userInfo.shop_info} onChange={onChangeValueInput} />
                </div></div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Shop size:
                    </span>
                    <InputText name='shop_size' placeholder="shop_size" value={userInfo.shop_size} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Years in operation:
                    </span>
                    <InputText name='years_in_operation' placeholder="years_in_operation" value={userInfo.years_in_operation} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Role in organization:
                    </span>
                    <InputText name='role_in_organization' placeholder="role_in_organization" value={userInfo.role_in_organization} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Created at:
                    </span>
                    <InputText name='role_in_organization' placeholder="created_ts" value={convertTimestampToDateTime(created_ts)} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Country
                    </span>
                    <InputText name='location_info' placeholder="location_info" value={location_info || ''} onChange={onChangeValueInput} />
                </div>
            </div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        First Commission:
                    </span>
                    <InputText name='first_commission_for_affiliater' placeholder="First commission for affiliater" value={userInfo.first_commission_for_affiliater} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Next Commission:
                    </span>
                    <InputText name='next_commission_for_affiliater' placeholder="Next commission for affiliater" value={userInfo.next_commission_for_affiliater} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Discount:
                    </span>
                    <InputText name='discount_for_user' placeholder="Discount for user" value={userInfo.discount_for_user} onChange={onChangeValueInput} />
                </div>
            </div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Time Coins:
                    </span>
                    <InputText name='time_coins' placeholder="time_coins" value={userInfo.time_coins} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Free Coins:
                    </span>
                    <InputText name='free_coins' placeholder="free_coins" value={userInfo.free_coins} onChange={onChangeValueInput} />
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Perm Coins
                    </span>
                    <InputText name='perm_coins' placeholder="perm_coins" value={userInfo.perm_coins} onChange={onChangeValueInput} />
                </div>
            </div>
            <div className='flex gap-2'>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Status:
                    </span>
                    <InputText name='status' placeholder="status" value={status} onChange={onChangeValueInput} />
                    {/* <Dropdown
                        name='status'
                        value={status}
                        defaultChecked={status}
                        onChange={(e) => onChangStatus()}
                        options={statusList}
                        optionLabel="code"
                        optionValue="code"
                        className="w-full md:w-14rem" /> */}
                </div>
                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Source:
                    </span>
                    <InputText name='source' placeholder="source" value={userInfo.source} onChange={onChangeValueInput} />
                </div>
            </div>
            <div className='flex flex-column gap-2'>

                <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon">
                        Roles:
                    </span>
                    <span className="p-fluid w-full">
                        <AutoComplete className='w-full' value={selectedRoles.map(i => i)} field="roles"
                            defaultChecked={selectedRoles[0]}
                            defaultValue={selectedRoles[0]}
                            multiple
                            onUnselect={
                                (e) => {
                                    const value = e.value
                                    const newArr = selectedRoles.filter(item => item !== value)
                                    setSelectedRoles(newArr)
                                }
                            } />
                    </span>
                </div>
                <div className="p-inputgroup flex-1 w-18rem">
                    <InputText name='add_roles' placeholder="Add roles" value={rolesInput} onChange={(e) => setRolesInput(e.target.value)} />
                    <Button onClick={() => {
                        rolesInput && setSelectedRoles([...userInfo.roles, rolesInput])
                        setRolesInput('')
                    }}>Add</Button>
                </div>
            </div>
            <div className='flex justify-content-center items-center '>
                <Button className='w-14rem flex justify-content-center' onClick={SaveEditRoles}>Save</Button>
            </div>
            <DataTable width={'100%'} paginator={false} value={listData}>
                <Column body={(data, options) => options.rowIndex + 1} header="#"  ></Column>
                <Column field="amount" header="Amount" filter sortable ></Column>
                <Column field="coins" header="Coins" filter sortable></Column>
                <Column field="currency" header="Currency" filter sortable ></Column>
                <Column field="gateway" header="Gateway" sortable filter ></Column>
                <Column field="name" header="Name" sortable filter ></Column>
                <Column field="status" header="Status" sortable filter ></Column>
                <Column field="feature_type" header="Type" sortable filter ></Column>
                <Column field="createAt" header="Create At" body={bodyFormattedDateTime} sortable></Column>
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div>
    )
}

export default DetailUser