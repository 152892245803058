export const menu = [
    // {
    //     label: 'Import Template',
    //     icon: 'pi pi-th-large',
    //     items: [{ label: 'Import Template', icon: 'pi pi-cloud-upload', to: '/template/import' }]
    // },
    {
        label: 'Content Model',
        icon: 'pi pi-th-large',
        items: [
            { label: 'Upload Content', icon: 'pi pi-cloud-upload', to: '/config/upload-content', key: 'MODEL_CONTENT_MANAGER' },
            { label: 'List Content', icon: 'pi pi-list', to: '/config/list-content', key: 'MODEL_CONTENT_MANAGER' },
            { label: 'List models', icon: 'pi pi-list', to: '/config/list-models', key: 'MODEL_CONTENT_MANAGER' },
            { label: 'List config', icon: 'pi pi-list', to: '/config/list-config', key: 'CONFIG_MANAGER' }
        ]
    },
    {
        label: 'Prompt Customize ',
        icon: 'pi pi-th-large',
        items: [{ label: 'List customize', icon: 'pi pi-list', to: '/custom-prompt/list-prompt', key: 'CUSTOMIZE_MANAGER' }]
    },
    {
        label: 'Pose',
        icon: 'pi pi-th-large',
        items: [{ label: 'List Pose', icon: 'pi pi-list', to: '/pose/list-pose', key: 'POSE_MANAGER' }]
    },
    {
        label: 'Whitelist',
        icon: 'pi pi-th-large',
        items: [
            { label: 'Whitelist', icon: 'pi pi-list', to: '/whitelist/list', key: 'WHITELIST_USER_MANAGER' },
            { label: 'List', icon: 'pi pi-list', to: '/whitelist/list-user', key: 'WHITELIST_FEATURE_MANAGER' }
        ]
    },
    {
        label: 'Link',
        icon: 'pi pi-link',
        items: [
            { label: 'Parameter description', icon: 'pi pi-list', to: '/links/param-des', key: 'LINK_MANAGER' },
            { label: 'SD instance', icon: 'pi pi-list', to: '/links/sd-instance', key: 'LINK_MANAGER' }
        ]
    },
    {
        label: 'Users Management',
        icon: 'pi pi-id-card',
        items: [{ label: 'List Users', icon: 'pi pi-list', to: '/users-management/list-users', key: 'USER_MANAGER' }]
    },
    {
        label: 'Languages Management',
        icon: 'pi pi-id-card',
        items: [{ label: 'Language', icon: 'pi pi-list', to: '/language/list-items', key: 'LANGUAGE_MANAGER' }]
    },
    {
        label: 'Affiliate',
        icon: 'pi pi-id-card',
        items: [{ label: 'Affiliate', icon: 'pi pi-list', to: '/affiliates/list-affiliates', key: 'AFFILIATE_MANAGER' }]
    },
    {
        label: 'Tutor',
        icon: 'pi pi-id-card',
        items: [{ label: 'List Tutor', icon: 'pi pi-list', to: '/tutor', key: 'TUTOR_MANAGER' }]
    },
    {
        label: 'Order',
        icon: 'pi pi-id-card',
        items: [{ label: 'List Order', icon: 'pi pi-list', to: '/order', key: 'ORDER_MANAGER' }]
    },
    {
        label: 'Team management',
        icon: 'pi pi-users',
        id: 'team-manager',
        items: [
            {
                label: 'Permission',
                icon: 'pi pi-key',
                to: '/admin-management/permission',
                key: 'TEAM_MANAGER'
            }
        ]
    },
    {
        label: 'Products',
        icon: 'pi pi-id-card',
        items: [{ label: 'List Products', icon: 'pi pi-list', to: '/products', key: 'PRODUCT_MANAGER' }]
    },
];
