import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { useEffect, useState } from 'react'
import ConfigService from '../../service/ConfigManagement/configService'
import { WhitelistStatusEnum } from '../../utilities/enums'

function ListStatusWaiting() {
    const [listData, setListData] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null);
    const getData = async () => {
        const dataApi = await ConfigService.getInstance().getAllListWait();
        setListData(dataApi.data)
    }
    const statusList = [
        { name: WhitelistStatusEnum.WAITING, code: WhitelistStatusEnum.WAITING },
        { name: WhitelistStatusEnum.APPROVED, code: WhitelistStatusEnum.APPROVED },
        { name: WhitelistStatusEnum.REJECTED, code: WhitelistStatusEnum.REJECTED },
    ];
    const onChangStatus = async (value:any,id:string) =>{
        setSelectedStatus(value)
        const body = {
            whitelistId:id,
            status: value
        } 
        const editStatus = await ConfigService.getInstance().editListWait(body);
        getData()
    }
    const actionTemplate = (data:any) =>{
        const status = data.status 
        return (
            <Dropdown 
            value={status} 
            onChange={(e) => onChangStatus(e.value,data._id)} options={statusList} 
            optionLabel="name"
            optionValue="code"
             className="w-full md:w-14rem" />
        )
    }
    const templateStatus = (data:any) =>{
        const status = data.status;
        const color = (status === 'WAITING') ? 'info' : (status === 'APPROVED') ? 'success' : 'danger';
        return (
            <Tag className="p-2 w-8rem text-white" severity={color} value={status}></Tag>
        )
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <div>
            <DataTable width={'100%'} value={listData} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} selectOnEdit={true}>
                <Column field="username" header="user name" filter filterPlaceholder="Search by user name"></Column>
                <Column field="feature_name" header="Feature name" filter filterPlaceholder="Search by feature name"></Column>
                <Column field="type" header="Type" filter filterPlaceholder="Search by Type"></Column>
                <Column field="status" header="Status" filter filterPlaceholder="Search by status" body={templateStatus}></Column>
                <Column  header="Edit Status" body={actionTemplate}></Column>
            </DataTable>
        </div>
    )
}

export default ListStatusWaiting