import { useEffect, useState } from 'react'
import ConfigService from '../../service/ConfigManagement/configService';
import { TabMenu } from 'primereact/tabmenu';
import ItemPrompt from './ItemPrompt';
import { Button } from 'primereact/button';
import ModalEditCustomize from './ModalEditCustomize';
import ModalAddCustomize from './ModalAddCustomize';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import NotifyController from '../../utilities/Toast';

function ListAllPromptCustomize() {
  const [listData, setListData] = useState<any[]>([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState('dressundefined');
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [dataAdd, setDataAdd] = useState();
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [dataEditModal, setDataEditModal] = useState();
  const [idEditModal, setIdEditModal] = useState('');
  const [openModalClone, setOpenModalClone] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [codeModalDelete, setCodeModalDelete] = useState('')
  const [listCodeModel, setListCodeModel] = useState<any[]>([])
  const [selectedModel, setSelectedModel] = useState(null);
  const [dataClone, setDataClone] = useState({
    name: '',
    active: false,
    data: [],
    type: ''
  })


  const getList = async () => {
    const data = await ConfigService.getInstance().getAllPromptCustomize();
    setListData(data.data)
  }

  const getListModelCodeByConfig = async () => {
    const { data } = await ConfigService.getInstance().getAllConfigs();
    // const listArrayCode: [string] = [];
    let arrayCode: any[] = [];
    // Lặp qua mỗi phần tử trong mảng ban đầu
    data.forEach((item: any) => {
      // Kiểm tra nếu 'status' là ACTIVE thì đưa giá trị 'code' vào mảng mới
      if (item.status === 'ACTIVE') {
        console.log('item', item);

        arrayCode.push({
          name: item.name,
          code: item.code,
          image: item.image
        });
      }
    });
    setListCodeModel(arrayCode)
  }

  const itemsType = listData.map((item) => ({
    label: item.content_model_code ? item.name + ' ' + item.content_model_code : item.name,
    id: item.type + item.content_model_code || item.type
  }));

  const editItem = () => {
    setIsOpenModalEdit(true)
    setDataEditModal(listData[activeIndex].data)
    setIdEditModal(listData[activeIndex]._id)
  }

  const addItem = () => {
    setIsOpenModalAdd(true)
    setDataAdd(listData[activeIndex])
  }

  const handelClickCloneModel = (data: any) => {
    setOpenModalClone(true);
    setDataClone({
      active: data.active,
      data: data.data,
      name: data.name,
      type: data.type
    })
  }

  const confirmCloneModel = async (dataClone: any, codeModel: any) => {
    const body = {
      ...dataClone,
      content_model_code: codeModel.code
    }
    const created = await ConfigService.getInstance().createCustomize(body);
    setOpenModalClone(false)
    getList()
  }

  const confirmDeleteModel = async (code: string) => {
    try {
      setOpenModalDelete(false)
      const dataDelete = await ConfigService.getInstance().deleteCustomize(code)
      getList()
      setActiveIndex(0);
      setActive(listData[0].type)
    } catch (error) {
      console.error('error', error);
      setOpenModalDelete(false)
      NotifyController.error('Something went wrong!')
    }
  }

  const handelClickDeleteModel = (data: any) => {
    try {
      const code = data.content_model_code;
      setOpenModalDelete(true)
      setCodeModalDelete(code)
    } catch (error) {
      console.error('error', error);
      NotifyController.error('Something went wrong!')
    }
  }

  useEffect(() => {
    getListModelCodeByConfig();
    getList();
  }, [])

  useEffect(() => {
    getList();
  }, [isOpenModalEdit])
  const countryOptionTemplate = (option: any) => {
    return (
      <div className="flex align-items-center w-full">
        <img alt={option.name} src={option.image} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.code}</div>
      </div>
    );
  };
  return (
    <div className='flex gap-3 flex-column'>
      {/* <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}  /> */}
      <div className='flex flex-row gap-2'>
        <Button className='w-2 flex gap-2 justify-content-center align-item-center' onClick={addItem}><i className="pi pi-plus"></i> Add new</Button>
        <Button className='w-2 flex gap-2 justify-content-center align-item-center' onClick={editItem}><i className="pi pi-pencil"></i> Edit </Button>
      </div>
      <TabMenu model={itemsType} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); setActive(e.value.id as any); }} />

      {listData?.map((data: any) => {
        return <>
          <div className={`${(active === (data.type + data.content_model_code) || active === data.type) ? null : 'hidden'}`}>
            {<div className='flex gap-2'>
              <Button onClick={() => handelClickCloneModel(data)}>Copy</Button>
              <Button className='p-button-danger' onClick={() => { handelClickDeleteModel(data) }}>Delete</Button>
            </div>}
            <ItemPrompt data={data} />
          </div>
        </>
      })}
      {openModalClone && <Dialog className='w-6' visible={openModalClone} onHide={() => setOpenModalClone(false)} header={'Can you clone this model?'} >
        <div className=' flex justify-content-center algin-items-center'>
          <Dropdown itemTemplate={countryOptionTemplate} value={selectedModel} onChange={(e) => {
            setSelectedModel(e.value)
          }} options={listCodeModel} optionLabel="code"
            placeholder="Select a Model" className="w-full md:w-14rem" />
        </div>
        {selectedModel && <Button onClick={() => { confirmCloneModel(dataClone, selectedModel) }}>Confirm</Button>}
      </Dialog>}
      {openModalDelete && <Dialog className='w-2' visible={openModalDelete} onHide={() => setOpenModalDelete(false)} header={'Can you Delete this model?'} >
        <div className='flex justify-content-center align-items-center gap-2'>
          <Button onClick={() => { setOpenModalDelete(false) }}>Cancel</Button>
          <Button className='p-button-success' onClick={() => { confirmDeleteModel(codeModalDelete) }}>Confirm</Button>
        </div>
      </Dialog>}
      {isOpenModalAdd && <ModalAddCustomize isOpenModalAdd={isOpenModalAdd} setIsOpenModalAdd={setIsOpenModalAdd} dataAdd={dataAdd} />}
      {isOpenModalEdit && <ModalEditCustomize isOpenModalEdit={isOpenModalEdit} setIsOpenModalEdit={setIsOpenModalEdit} dataEditModal={dataEditModal} idEditModal={idEditModal} setDataEditModal={setDataEditModal} />}
    </div>
  )
}

export default ListAllPromptCustomize