export enum CUSTOM_FORM_DIALOG_FIELD_TYPE {
    text,
    areaText,
    number,
    select,
    slider,
    multiSelect,
    richText,
    date,
    switchConfirm
}

export const MAXIMUM_WARNING_POINT = 300