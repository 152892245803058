import ListAll from "../pages/LanguageManagement/ListAll";
import ListUsers from "../pages/ListUsers";
import ListAllAffiliate from "../pages/affiliateManagement/ListAllAffiliate";
import ListLinks from "../pages/allLinks/ListLinks";
import ListWebUI from "../pages/allLinks/ListWebUI";
import ListAllConfig from "../pages/configManagement/AllConfig/ListAllConfig";
import ListAllStyles from "../pages/configManagement/AllStyles/ListAllStyles";
import ListConfigs from "../pages/configManagement/ConfigCustomize";
import UploadConfig from "../pages/configManagement/UploadConfig/UploadConfig";
import AddOrder from "../pages/orderManagement/addOrder";
import EditOrder from "../pages/orderManagement/editOrder";
import ListOrder from "../pages/orderManagement/listOrder";
import AdminPermissionManagement from "../pages/permissionManagement/PermissionManagement";
import ListAllPose from "../pages/poseManagement/ListAllPose";
import ListProducts from "../pages/products/ListProducts";
import ListAllPromptCustomize from "../pages/promptCustomizeManagement/ListAllPromptCustomize";
import ImportTemplate from "../pages/templateManagement/ImportTemplate";
import AddTutor from "../pages/tutorManagement/addTutor";
import EditTutor from "../pages/tutorManagement/editTutor";
import ListTutor from "../pages/tutorManagement/listTutor";
import ListStatusWaiting from "../pages/whitelist/ListStatusWaiting";
import ListWhitelist from "../pages/whitelist/ListWhitelist";
interface Iroute {
    path: string;
    element?: any;
    label?: string;
    childs?: Array<{
        path: string;
        element: any;
        label?: string;
        child?: any
    }>
}
export const routes: Iroute[] = [
    {
        path: "template",
        label: 'Template',
        childs: [
            {
                path: "import",
                label: 'Import Template',
                element: <ImportTemplate />
            },
        ]
    },
    {
        path: "config",
        label: 'Config',
        childs: [
            {
                path: "upload-content",
                label: 'Upload Config',
                element: <UploadConfig />
            },
            {
                path: "list-content",
                label: 'All Configs',
                element: <ListAllConfig />
            },
            {
                path: "list-models",
                label: 'All Styles',
                element: <ListAllStyles />
            },
            {
                path: "list-config",
                label: 'List config',
                element: <ListConfigs />
            },
        ]
    },
    {
        path: "custom-prompt",
        label: 'Prompt Customize',
        childs: [
            {
                path: "list-prompt",
                label: 'All Prompt',
                element: <ListAllPromptCustomize />,
            }
        ]
    },
    {
        path: "pose",
        label: 'List pose',
        childs: [
            {
                path: "list-pose",
                label: 'All Pose',
                element: <ListAllPose />,
            }
        ]
    },
    {
        path: "whitelist",
        label: 'whitelist',
        childs: [
            {
                path: "list",
                label: 'Whitelist',
                element: <ListWhitelist />,
            },
            {
                path: "list-user",
                label: 'List status',
                element: <ListStatusWaiting />,
            }
        ]
    },
    {
        path: "links",
        label: 'Links',
        childs: [
            {
                path: "param-des",
                label: 'Parameter description',
                element: <ListLinks />,
            },
            {
                path: "sd-instance",
                label: 'SD instance',
                element: <ListWebUI />,
            }
        ]

    },
    {
        path: "users-management",
        label: 'Users Management',
        childs: [
            {
                path: "list-users",
                label: 'List Users',
                element: <ListUsers />,
            },
        ]

    },
    {
        path: "language",
        label: 'Language',
        childs: [
            {
                path: "list-items",
                label: 'List',
                element: <ListAll />,
            },
        ]

    },
    {
        path: "affiliates",
        label: 'Affiliate',
        childs: [
            {
                path: "list-affiliates",
                label: 'List affiliate',
                element: <ListAllAffiliate />,
            },
        ]

    },
    {
        path: "tutor",
        label: 'Tutor',
        childs: [
            {
                path: "add",
                label: 'Create Tutor',
                element: <AddTutor />,
            },
            {
                path: "edit/:id",
                label: 'Edit Tutor',
                element: <EditTutor />,
            },
            {
                path: "",
                label: 'List Tutor',
                element: <ListTutor />,
            },
        ]

    },
    {
        path: 'admin-management',
        label: 'Team Management',
        // id: 'team-manager',
        childs: [
            {
                path: 'permission',
                label: 'Permission',
                element: <AdminPermissionManagement />
            }
        ]
    },
    {
        path: 'order',
        label: 'Order Management',
        childs: [
            {
                path: '',
                label: 'Order',
                element: <ListOrder />
            },
            {
                path: "edit/:id",
                label: 'Edit Order',
                element: <EditOrder />,
            },
            {
                path: "add",
                label: 'Create Order',
                element: <AddOrder />,
            },
        ]
    },
    {
        path: 'products',
        label: 'Products Management',
        childs: [
            {
                path: '',
                label: 'Products',
                element: <ListProducts />
            },
        ]
    },
    // {
    //     path: "login",
    //     label: 'Login',
    //     childs: [
    //         {
    //             path: "login",
    //             label: 'Login',
    //             element: <Login />,
    //         }
    //     ]
    // }
]
