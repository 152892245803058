import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ConfigService from '../../service/ConfigManagement/configService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

function DetailsAffiliate() {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [dataDetail, setDataDetail] = useState([]);
    const [affiliatePrograms, setAffiliatePrograms] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [dates, setDates] = useState<any>(undefined);
    const [totalCommission, setTotalCommission] = useState({
        totalCommissionAmount: 0,
        totalVndAmount: 0
    });
    const [displayDialog, setDisplayDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [programDetails, setProgramDetails] = useState({
        userId: id,
        affiliateName: '',
        firstCommissionPercent: 0,
        nextCommissionPercent: 0,
        discountForUser: 0,
        programId: ''
    });

    const getDetailAffiliater = async (id: string, page: number, limit: number, startDate: number, endDate: number) => {
        const data = await ConfigService.getInstance().getDetailsAffiliates(id, page, limit, startDate, endDate);
        setDataDetail(data.result);
        setTotalRecord(data.totalRecord);
        setTotalCommission({
            totalCommissionAmount: data.totalCommissionAmount || 0,
            totalVndAmount: data.totalVndAmount || 0
        });
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        const date = new Date(timestamp);
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return <span>{formattedDateTime}</span>;
    };

    const formatPriceVND = (data: any) => {
        const price = data.vndAmount;
        return <span>{price?.toLocaleString() + ' VND'}</span>;
    };

    const formatCommissionAmountVND = (data: any) => {
        const price = data.commissionAmount;
        return <span>{price?.toLocaleString() + ' VND'}</span>;
    };

    const onPageChange = (event: any) => {
        setPage(event.page + 1);
        setLimit(event.rows);
        setFirst(event.first);
    };

    const handleDialogShow = () => {
        setDisplayDialog(true);
        setIsEditing(false);
        setProgramDetails({
            userId: id,
            affiliateName: '',
            firstCommissionPercent: 0,
            nextCommissionPercent: 0,
            discountForUser: 0,
            programId: ''
        });
    };

    const handleDialogHide = () => {
        setDisplayDialog(false);
    };

    const handleInputChange = (e: any, field: string) => {
        const value = e.value || e.target.value
        setProgramDetails({
            ...programDetails,
            [field]: value
        });
    };

    const handleProgramSubmit = async () => {
        if (isEditing) {
            await ConfigService.getInstance().updatePartnerAffiliate(programDetails);
        } else {
            await ConfigService.getInstance().setPartnerAffiliate(programDetails);
        }
        handleDialogHide();
        getAffiliatePrograms();
    };

    const handleEditProgram = (program: any) => {
        setProgramDetails({
            userId: id,
            affiliateName: program.affiliate_name,
            firstCommissionPercent: program.first_commission_percent,
            nextCommissionPercent: program.next_commission_percent,
            discountForUser: program.discount_for_user,
            programId: program._id
        });
        setIsEditing(true);
        setDisplayDialog(true);
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={handleDialogHide} className="p-button-text" />
                <Button label="Submit" icon="pi pi-check" onClick={handleProgramSubmit} autoFocus />
            </div>
        );
    };

    const getAffiliatePrograms = async () => {
        const data = await ConfigService.getInstance().getAffiliatePrograms(id);
        setAffiliatePrograms(data.data);
    };

    useEffect(() => {
        if (dates && dates.length > 0 && id) {
            const startDate = dates[0].getTime();
            let endDate = 0;
            if (dates.length > 1 && dates[1]) {
                endDate = dates[1].getTime();
            }
            if (startDate > 0 && endDate > 0) {
                getDetailAffiliater(id, page, limit, startDate, endDate);
            }
        } else {
            const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();
            const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime();
            id && getDetailAffiliater(id, page, limit, firstDayOfMonth, lastDayOfMonth);
        }
    }, [id, page, limit, dates]);

    useEffect(() => {
        getAffiliatePrograms();
    }, []);
    
    const actionBodyTemplate = (rowData: any) => {
        return <Button label="Edit" icon="pi pi-pencil" onClick={() => handleEditProgram(rowData)} />;
    };

    return (
        <div>
            <div className='flex justify-content-center align-items-center gap-4'>
                Email: <InputText readOnly value={email || ''} />
                Tổng Giá Đã Mua: <InputText readOnly value={(totalCommission.totalVndAmount)?.toLocaleString() + ' VND'} />
                Tổng Hoa Hồng Nhận Được: <InputText readOnly value={(totalCommission.totalCommissionAmount)?.toLocaleString() + ' VND'} />
            </div>
            <div className='flex gap-4'>
                <div>
                    <Calendar placeholder='Select start-end date' selectionMode="range" id="range" readOnlyInput value={dates} onChange={(e) => { setDates(e.value) }}></Calendar>
                </div>
            </div>
            <DataTable width={'100%'} value={dataDetail} paginator={false} selectOnEdit={true}>
                <Column field="email" header="Email" filter filterPlaceholder="Search by email"></Column>
                <Column field="username" header="Username" filter filterPlaceholder="Search by username" ></Column>
                <Column field="commissionPercent" header="% Hoa Hồng" filter filterPlaceholder="Search by commissionPercent" ></Column>
                <Column field="created_ts" header="Thời điểm thanh toán" body={bodyFormattedDateTime} sortable></Column>
                <Column field="vndAmount" header="Giá mua" sortable body={formatPriceVND}></Column>
                <Column field="commissionAmount" header="Tiền hoa hồng" sortable body={formatCommissionAmountVND}></Column>
            </DataTable>
            <Paginator first={first} rows={limit} totalRecords={totalRecord} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
            <Button label="Tạo Chương Trình" onClick={handleDialogShow} />

            <Dialog header={isEditing ? "Edit Program" : "Create Program"} visible={displayDialog} style={{ width: '50vw' }} footer={renderFooter()} onHide={handleDialogHide}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="affiliateName">Affiliate Name</label>
                        <InputText id="affiliateName" value={programDetails.affiliateName} onChange={(e) => handleInputChange(e, 'affiliateName')} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="firstCommissionPercent">First Commission Percent</label>
                        <InputNumber id="firstCommissionPercent" value={programDetails.firstCommissionPercent} onChange={(e) => handleInputChange(e, 'firstCommissionPercent')} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="nextCommissionPercent">Next Commission Percent</label>
                        <InputNumber id="nextCommissionPercent" value={programDetails.nextCommissionPercent} onChange={(e) => handleInputChange(e, 'nextCommissionPercent')} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="discountForUser">Discount for User</label>
                        <InputNumber id="discountForUser" value={programDetails.discountForUser} onChange={(e) => handleInputChange(e, 'discountForUser')} />
                    </div>
                </div>
            </Dialog>

            <h3>Affiliate Programs</h3>
            <DataTable value={affiliatePrograms} paginator={false}>
                <Column field="affiliate_name" header="Affiliate Name"></Column>
                <Column field="first_commission_percent" header="First Commission Percent"></Column>
                <Column field="next_commission_percent" header="Next Commission Percent"></Column>
                <Column field="discount_for_user" header="Discount for User"></Column>
                <Column body={actionBodyTemplate} header="Actions"></Column>
            </DataTable>
        </div>
    );
}

export default DetailsAffiliate;
