
import Axios from 'axios';
import { httpDelete, httpGet, httpPost, httpPut } from '../HttpService';
import NotifyController from '../../utilities/Toast';

export default class ConfigService {
    private static instance: ConfigService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new ConfigService();
        }
        return this.instance;
    }
    async postUploadConfig(data: any) {
        const uploaded = await httpPost('dashboard/import-style', data)
        return uploaded.data
    }

    async importTemplate(data: any) {
        const uploaded = await httpPost('dashboard/import-style-v2', data)
        return uploaded.data
    }

    async putEditConfig(data: any) {
        const uploaded = await httpPut('dashboard/style', data)
        return uploaded.data
    }

    async deleteConfig(data: any) {
        const uploaded = await httpPost('dashboard/delete-styles', data)
        return uploaded
    }

    async getAllConfigs() {
        const data = await httpGet('dashboard/style')
        return data.data
    }
    async getAllStyles() {
        const token = localStorage.getItem('accessToken');

        const data = await Axios.get(process.env.REACT_APP_BE_WEB_API + '/sdstyle', {
            headers: {
                'Authorization': `Basic ${token}`
            },
        })
        return data.data
    }
    async postEditStyles(data: any) {
        const token = localStorage.getItem('accessToken');
        const result = await Axios.post(process.env.REACT_APP_BE_WEB_API + '/sdstyle', data, {
            headers: {
                'Authorization': `Basic ${token}`
            },
        })
        return result.data
    }
    async postAddStyles(data: any) {
        const token = localStorage.getItem('accessToken');
        const result = await Axios.post(process.env.REACT_APP_BE_WEB_API + '/sdstyle', data, {
            headers: {
                'Authorization': `Basic ${token}`
            },
        })
        return result.data
    }
    async deleteStyles(id: string) {
        const token = localStorage.getItem('accessToken');
        const result = await Axios.delete(process.env.REACT_APP_BE_WEB_API + '/sdstyle?id=' + id, {
            headers: {
                'Authorization': `Basic ${token}`
            },
        })
        return result.status
    }


    async getAllPromptCustomize() {
        const data = await httpGet('/dashboard/custom')
        return data.data
    }
    async getAllNewConfigs() {
        const data = await httpGet('/config')
        return data.data
    }
    async editConfig(config_key: string, config_value: string) {
        const data = await httpPut('/config', {
            key: config_key,
            value: config_value
        })
        return data.data
    }
    async deleteNewConfig(config_key: string) {
        const data = await httpDelete(`/config/${config_key}`)
        return data.data
    }

    async putEditPromptCustomize(data: any) {
        const uploaded = await httpPut('dashboard/custom', data)
        return uploaded.data
    }

    async addNewConfigs(dataValue: any) {
        const data = await httpPost('dashboard/style', dataValue)
        return data.data
    }

    async uploadImage(file: any) {
        const data = await httpGet(`dashboard/signed-url?fileName=${new Date().getTime()}-${file.name}&fileType=${file.type}`)
        return data.data
    }

    async getCheckpoints() {
        const data = await httpGet('dashboard/sdstyle')
        return data.data
    }
    async getAllWhitelist() {
        const data = await httpGet('/dashboard/whitelist')
        return data.data.data
    }
    async getAllListWait() {
        const data = await httpGet('/dashboard/whitelist-feature');
        return data.data
    }
    async editListWait(body: {
        whitelistId: string,
        status: string
    }) {
        const data = await httpPut('/dashboard/whitelist-feature', body);
        return data.data
    }
    async editRolesByIdUser(body: {
        userId: string,
        editData: {
            roles: any[]
        }
    }) {
        const data = await httpPost('/dashboard/edit-users', body);
        return data.data
    }
    async editWhitelist(body: {
        whitelistId: string,
        status: string
    }) {
        const data = await httpPut('/dashboard/whitelist', body);
        return data.data
    }
    uploadFileS3(file: any, signedRequest: string, url: string) {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedRequest);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    return url
                } else {
                    //   alert("Could not upload file.");
                }
            }
        };
        xhr.send(file);
    };
    async getAllPose(type: string) {
        const data = await httpGet(`dashboard/poses?type=${type}`)
        return data.data
    }
    async editPose(type: string, dataPose: any) {
        const data = await httpPut(`dashboard/poses?type=${type}`, dataPose)
        return data.data
    }
    async getAllWebUI(dataQuery: any) {
        const token = localStorage.getItem('accessToken');
        const data = await Axios.post(process.env.REACT_APP_BE_WEB_API + '/runtime/query', dataQuery, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
        return data.data
    }
    async getAllKeyI18n(page: number, limit: number, valueSearch: string) {
        const data = await httpGet(`/dashboard/i18n?page=${page}&limit=${limit}&searchValue=${valueSearch}`)
        return data.data.data
    }
    async putEditKeyI18n(key: string, value: string) {
        const data = await httpPut('/dashboard/i18n', { key, value })
        return data.data
    }
    async getAllAffiliates(page: number, limit: number, searchParams: string, startDate: number, endDate: number) {
        const { data } = await httpGet(`/dashboard/affiliaters?page=${page}&limit=${limit}&key=${searchParams}&startDate=${startDate}&endDate=${endDate}`)
        return data.data
    }
    async getDetailsAffiliates(id: string, page: number, limit: number, startDate: number, endDate: number) {
        const { data } = await httpGet(`/dashboard/affiliater-detail?&affiliater_id=${id}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`)
        return data.data
    }
    async createCustomize(body: any) {
        try {
            const data = await httpPost('dashboard/custom', body);
            return data.data
        } catch (error) {
            NotifyController.error('There is exists custom item with content model code')
        }
    }
    async deleteCustomize(code: string) {
        const data = await httpDelete(`dashboard/custom/${code}`);
        return data.data
    }
    // getConfigDetail(id: string) {
    //     return httpGet('assets/demo/data/content-detail.json').then((res) => res.data.data);
    // }

    // deleteConfig(id: string) {
    //     return httpDelete('').then((res) => res.data.data);
    // }
    async createTutor(body: any) {
        try {
            const data = await httpPost('dashboard/create-tutor', body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getTutors(page: number, limit: number) {
        try {
            const data = await httpGet(`dashboard/read-tutor?page=${page}&limit=${limit}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getTutorDetail(id?: string) {
        const { data } = await httpGet(`/tutor/detail?id=${id}`);
        return data.data;
    }

    async updateTutor(body: any) {
        try {
            const data = await httpPost('dashboard/update-tutor', body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async deleteTutor(id: string) {
        try {
            const data = await httpGet(`dashboard/delete-tutor?id=${id}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getOrders(page: number, limit: number) {
        try {
            const data = await httpGet(`dashboard/read-order?page=${page}&limit=${limit}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getProducts() {
        try {
            const data = await httpGet(`dashboard/products`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getProductById(id: string) {
        try {
            const data = await httpGet(`dashboard/products/${id}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }


    async updateProduct(body: any) {
        try {
            const data = await httpPut(`dashboard/products`, body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async addOrder(body: any) {
        try {
            const data = await httpPost(`dashboard/create-order`, body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getOrderById(id?: string) {
        try {
            const data = await httpGet(`dashboard/order/${id}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async updateOrder(body: any) {
        try {
            const data = await httpPost(`dashboard/update-order`, body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async setPartnerAffiliate(body: any) {
        try {
            const data = await httpPost(`dashboard/set-partner-affiliate`, body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async getAffiliatePrograms(userId: any) {
        try {
            const data = await httpGet(`dashboard/affiliate-programs?userId=${userId}`);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }

    async updatePartnerAffiliate(body: any) {
        try {
            const data = await httpPut(`dashboard/affiliate-program`, body);
            return data.data
        } catch (error: any) {
            NotifyController.error(error.message)
        }
    }
}
